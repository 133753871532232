<template lang="pug">
#promo_ru_index

  //- section
  //-   .hero.is-light.is-bold: .hero-body: .container.content
  //-     .columns
  //-       .column.has-text-centered(style='align-self: center')

  //-         //- img(src="@/assets/logos/logo.png")

  //- section.section
  //-   .columns.is-centered
  //-     .column.is-8
  //-       .message.is-danger: .message-body.has-text-left
  //-         .columns.is-vcentered.is-gapless
  //-           .column
  //-             .is-size-4 «Но ведь это нудно...»
  //-             .is-size-4 «У меня нет времени...»
  //-             .is-size-4 «Я пробовал что-то подобное, но бросил...»
  //-             .is-size-4 «И вообще, причём тут пчёлы???»
  //-           .column.is-narrow.has-text-centered
  //-             div: img(src='@/assets/bees/skeptical1.png', style='height: 120px;')
  //-             i пчёл-скептик

  section.hero.is-light.is-success
    .hero-body: .container.content.has-text-centered
      h1.title.is-2 Домашняя бухгалтерия ХаниМани
      h2.subtitle.is-4 Всё под вашим контролем, безопасно и анонимно

  section.section.content
    .has-text-centered: img(src='@/assets/bees/with_incomes_expenses.png', style='width: 100px;')
    .title.is-2.has-text-centered
      |  Ручной учёт для полного контроля*
    .subtitle.is-6.has-text-centered(style='margin-bottom: 0')
      | * То есть не нужен доступ к вашему онлайн-банку (но можно импортировать свои существующие данные из CSV)
    .subtitle.is-4.has-text-centered
      | Учёт всех трат сделает вас более внимательным по отношению к деньгам<br>
      | И это, в итоге, улучшит ваше благосостояние

    .tile.is-ancestor
      .tile.is-parent.is-7: .tile.is-child.box
        .title.is-4 В календаре сразу видно все доходы и расходы
        ul
          li Запланируйте повторяющиеся расходы и потом просто подтверждайте их по факту
          li Вносите транзакции, просто щёлкнув по нужному дню
        hm_zoom: img(src='./assets/calendar.png', class='frame', v-img="true")
      .tile.is-parent
        .tile.is-child.box
          .title.is-4 Упрощайте себе жизнь:
          ul
            li Необязательно вводить каждый расход отдельно, можно комбинировать
            li Записывайте и сумму, и описание вместе
            li А категории потом покажут наглядную статистику
          .has-text-centered
            hm_zoom: img(src='./assets/transactions_form.png', class='frame', v-img="true")

  section.section.content
    .has-text-centered: img(src='@/assets/bees/forecast_bee.png', style='height: 130px;')
    .title.is-2.has-text-centered
      |  Планирование на основе денежного потока, а не жёстких бюджетов
    .subtitle.is-4.has-text-centered
      | Планируете будущие доходы и расходы в календаре.<br>
      | ХаниМани прогнозирует ваш денежный поток на год вперёд.
    .tile.is-ancestor
      .tile.is-parent: .tile.is-child.box
        .title.is-4 Распределите деньги по назначению
        p ХаниМани подскажет, сколько денег должно быть:
        ul
          li зарезервировано на будущие расходы (Резервы),
          li отложено на сбережения (Цели),
          li сколько можно потратить свободно (ХаниМани).
        img(src='./assets/envelopes.png', class='frame')
      .tile.is-parent.is-7
        .tile.is-child.box
          .title.is-4 Прогноз денежного потока
          p ХаниМани прогнозирует будущие траты на год вперёд и предупреждает вас, если вдруг в будущем не хватит денег на что-то («кассовые разрывы»).
          p Просто запланируйте по-максимуму все будущие расходы, и система сама подскажет, как себя вести, чтобы на всё хватило денег. При этом не забыв ни трат на дни рождения и другие праздники, ни ежегодных крупных расходов.
          p Куда уходят деньги — легко посмотреть и в онлайн-банке. В ХаниМани фокус на планировании будущего.
          hm_zoom.has-text-centered: img(src='./assets/forecast.gif', class='frame', v-img="true")

  //- section.section
  //-   .container: .columns
  //-     .column
  //-     .column.is-narrow
  //-       .message.is-warning: .message-body.has-text-centered
  //-         .columns.is-vcentered.is-mobile.is-gapless
  //-           .column.is-narrow
  //-             div: img(src='@/assets/bees/thinking.png', style='height: 90px; transform: scaleX(-1); margin-right: 10px')
  //-           .column: .is-size-2 А подробнее?
  //-     .column

  section.section.content
    .has-text-centered: img(src='@/assets/bees/cool.png', style='height: 100px;')
    .title.is-2.has-text-centered
      | Отчёты такие же крутые, как пчёлы
    .subtitle.is-4.has-text-centered
      | Подробные и наглядные, но не перегруженные
    .tile.is-ancestor
      .tile.is-parent.is-vertical
        .tile.is-child.box
          .title.is-4 Отчёты за любой период
          .has-text-centered
            hm_zoom: img(src='./assets/reports.png', class='frame', v-img="true", style='max-height: 300px;')
        .tile.is-child.box
          .title.is-4 Будущие балансы счетов
          hm_zoom.has-text-centered: img(src='./assets/reports_future_balance.png', class='frame', v-img="true")
      .tile.is-parent.is-vertical
        .tile.is-child.box
          .title.is-4 По категориям
          hm_zoom.has-text-centered: img(src='./assets/reports_categories.png', class='frame', v-img="true", style='height: 400px;')
        .tile.is-child.box
          .title.is-4 Общее состояние
          hm_zoom.has-text-centered: img(src='./assets/reports_net_worth.png', class='frame', v-img="true")

  //- section.section
  //-   .container: .columns
  //-     .column
  //-     .column.is-narrow
  //-       .message.is-success: .message-body.has-text-centered
  //-         .columns.is-vcentered.is-mobile.is-gapless
  //-           .column.is-narrow
  //-             div: img(src='@/assets/bees/interested.png', style='height: 110px;margin-right: 10px')
  //-           .column: .is-size-1 А ещё!
  //-     .column

  section.section.content
    .has-text-centered: img(src='@/assets/bees/piggy_bank.png', style='height: 120px;')
    .title.is-2.has-text-centered Приятно для вас, полезно для ваших финансов
    .tile.is-ancestor
      .tile.is-parent: .tile.is-child.box
        .title.is-4 Для тех, кто хочет всегда иметь деньги за душой
        p ХаниМани не для тех, кому не хватает даже на еду. Хотя может помочь и в такой ситуации.
        p ХаниМани для тех, кто хочет, чтобы деньги не уходили куда попало, а сохранялись и множились.
        ul
          li Отслеживайте, сколько вы сохраняете каждый месяц
          li Отслеживайте прогресс своих финансовых целей
          li Следите за своими тратами
        .has-text-centered: img(src='@/assets/bees/with_honey.png', style='width: 130px;')

      .tile.is-parent: .tile.is-child.box
        .title.is-4 ХаниМани работает незаметно, но верно
        p Записать число в систему — элементарно, не требует каких-то знаний или умений, и занимает всего полчаса в неделю. Следить за своими финансами — это просто очень полезная привычка, которой нас никто не научил. Так же просто, как зубы почистить.
        p Вам не нужны бюджеты и ограничения, вы можете даже не прикладывать никаких сознательных усилий по изменению своих финансовых привычек. Они сами поменяются, просто потому, что вы станете больше внимать уделять своим финансам.
        .has-text-centered
          img(src='@/assets/bees/on_toilet.png', style='width: 130px;')
          br
          small Не перенапрягайтесь

      .tile.is-parent.is-vertical
        .tile.is-child.box.content
          .title.is-4 Не усложняйте себе жизнь!
          p
            | Домашняя бухгалтерия должна быть простой и приятной. Учёт каждой копейки, жёсткие бюджеты, экономия на спичках, подробно расписанные планы и, боже упаси, дебет-кредит — бррррр....
            br
            strong  В ХаниМани всё просто.
        .tile.is-child.box
          .title.is-4 ХаниМани в Кармане
          p Вы можете вносить транзакции даже на ходу, потому что ХаниМани всегда с вами:
          p.has-text-centered
            .columns.is-gapless.is-mobile
              //- https://app.shotbot.io/#/project
              .column: hm_zoom: img.il_frame(v-img:mobile='{thumbnails: true}', src='./assets/mobile/list.png')
              .column: hm_zoom: img.il_frame(v-img:mobile='{thumbnails: true}', src='./assets/mobile/form.png')
              .column: hm_zoom: img.il_frame(v-img:mobile='{thumbnails: true}', src='./assets/mobile/stats.png')

  section.section.content
    .title.is-2.has-text-centered ХаниМани работает с 2011 года
    .tile.is-ancestor
      .tile.is-parent: .tile.is-child.box
        .columns
          .column
            .title.is-4 О системе
            .columns
              .column.is-narrow
                hm_slideshow(style="width: 150px; height: 190px")
                  div: img(src='@/assets/me1.png', style='width: 150px;')
                  div(style='display: none'): img(src='@/assets/me2.png', style='width: 150px;')
              .column
                p Всем приветики, меня зовут Ильдар.
                p Я запустил первую версию ХаниМани в 2011 году. Я хотел простую и наглядную систему по учёту своих финансов. Все другие инструменты были или слишком строгими, или слишком скучными, или просто заброшенными.
                p Сперва ХаниМани пользовался только я и ещё несколько моих друзей, но потом откуда-то налетело ещё очень много пользователей, и теперь ХаниМани — это мой небольшой, но прибыльный бизнес.
                p Я до сих пор пользуюсь ХаниМани, и система стала самым полезным помощником для нашей семьи. Я хочу, чтобы она помогла и вам.

          .column.is-narrow
            .title.is-4 Люди любят ХаниМани
            p <strong>100 отзывов</strong> в одной картинке:
            hm_zoom.has-text-centered: img(src='./assets/about/testimonials_heart.png', class='frame', v-img="true", style='height: 200px')

  section.hero.is-light.is-bold: .hero-body: .container.content.has-text-centered
    .title.is-2 Возьмите финансы под контроль
    .subtitle.is-4 ХаниМани — это просто, и это работает
    a.button.is-info.is-rounded(:href="HM.demo_url") 👀 Посмотреть демо
    span.is-size-4 &nbsp;&nbsp;&nbsp;&nbsp;или&nbsp;&nbsp;&nbsp;&nbsp;
    a.button.is-success.is-rounded(:href="HM.app_url + '/app/sign_up'") ✔ Начать
    .has-text-right: router-link.button.is-text(to="/ru/about") Как ХаниМани работает »
</template>

<script>
export default {
  components: {
    hm_zoom: require('@/components/hm_zoom.vue').default,
    hm_slideshow: require('@/components/hm_slideshow.vue').default
  },
  metaInfo: {
    title: 'ХаниМани — домашняя бухгалтерия онлайн, которую обожают',
    meta: [
      { name: 'description', content: 'Домашняя бухгалтерия ХаниМани — простой и приятный способ вести личный и семейный бюджет онлайн. Вкусно для вас, полезно для ваших финансов!' }
    ]
  },
  data () {
    return { menu_visible: false }
  }
}
</script>

<style lang='sass'>

</style>
